import * as React from "react";
import BoxContainer from "../../components/BoxContainer";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";
import iconResolver from "../../core/icon-resolver";
import { Link } from "gatsby";

function DoubleColTwo({
  data,
  background = "bg-gradient-to-t from-gradient1  to-white",
}) {
  const { dataType = 0 } = data;
  return (
    <>
      <BoxContainer
        background={background}
        className="items-center py-24 xs:px-5 2xl:px-0"
      >
        <div className="max-w-6xl mx-auto">
          <div className="grid xs:grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-24 lg:gap-x-32 items-center pb-28">
            <Text
              highlightedText={
                dataType === 1
                  ? data.highlightWords
                  : textResolver(data, "highlightWords")
              }
              highlightedColor={data.highlightedColor}
              // className="text-5xl leading-[60px] text-blue font-primary font-semibold"
              className="text-[39px] leading-[65px] text-blue font-primary font-bold"
              as="h2"
            >
              {dataType === 1 ? data.title : textResolver(data, "title")}
            </Text>
            <Text
              as="div"
              className="text-lg leading-8 text-blue font-opensans"
            >
              {dataType === 1
                ? data.description
                : textResolver(data, "description")}
            </Text>
          </div>
          <div className="max-w-6xl mx-auto">
            <div className="md:grid grid-cols-2 gap-y-36 md:gap-x-24 lg:gap-x-32">
              {data?.maindata.map((item, i) => (
                <ul
                  className="flex xs:flex-col gap-4 xs:items-center sm:items-start mb-12 md:mb-0"
                  key={i}
                >
                  {item.icon && (
                    <div className="w-min">{iconResolver(item.icon)}</div>
                  )}
                  <Text
                    as="li"
                    className="text-2xl text-blue font-primary font-semibold xs:text-center sm:text-left md:min-h-[5rem] lg:min-h-0 list-none"
                  >
                    {dataType === 1
                      ? item.heading
                      : textResolver(item, "heading")}
                  </Text>

                  <Text
                    as="div"
                    className="text-lg text-blue leading-8 xs:text-center sm:text-left xs:mb-5 md:mb-0 md:min-h-[14rem] lg:min-h-xl  xl:min-h-[8rem]"
                  >
                    {dataType === 1
                      ? item.maintext
                      : textResolver(item, "maintext")}
                  </Text>
                  {item.cta ? (
                    <Link
                      to={
                        dataType === 1
                          ? item.cta.href
                          : textResolver(item?.cta, "href")
                      }
                      className="text-lg text-midBlue font-primary font-semibold hover:underline hover:text-yellow md:mt-8 0px:text-center md:text-left transition-colors"
                    >
                      {dataType === 1
                        ? item.cta.text
                        : textResolver(item?.cta, "text")}{" "}
                      <span className="text-xl"> &rarr;</span>
                    </Link>
                  ) : null}
                </ul>
              ))}
            </div>
          </div>
        </div>
      </BoxContainer>
    </>
  );
}
export default DoubleColTwo;
