import React from "react";
import Seo from "../seo";
import Banner from "../../LegalTranscription/Home/Banner";
import TextRow from "../../LegalTranscription/Pillar/TextRow";
import MainUsp from "../../LegalTranscription/Home/MainUsp";
import TwoColGridWithOrder from "../../LegalTranscription/Pillar/TwoColGridWithOrder";
import GlobalCta from "../../components/GlobalCta";
import Onside from "../../LegalTranscription/Home/Onside";
import FAQSimple from "../../components/FAQSimple";
import CtaWithForm from "../../LegalTranscription/Home/CtaWithForm";
import Footer from "../../LegalTranscription/Home/Footer";
import TranslationServiceGrid from "../../components/TranslationServiceGrid";
import CertificationSamples from "../../components/CertificationSamples";
import DoubleCol from "../../components/DoubleCol";

import {
  legalTranscriptionHeaderdata,
  bannerSealData,
  contactusPopupData,
  thankYouPopUpData,
  legalTranscriptionFooterData,
  stepsData,
  Uspdata,
  bannerDataNew,
  isoCertificationsData,
} from "../../LegalTranscription/utils/sample-data";
import DoubleColTwo from "../../LegalTranscription/Home/DoubleColTwo";
import TranslationServiceGrid1 from "../../components/TranslationServiceGrid/TranslationServicesGrid1";
import Layout from "../../components/Layout";
import MultilingualSeoPricing from "../../FormalTranslation/MultilingualSeoService/index";
import Newtranslation from "../../FormalTranslation/NewTranslation";
import { pricingData } from "../../FormalTranslation/MultilingualSeoService/config";
import SupportedLanguages from "../../components/SupportedLanguages";
import CaseStudyGrid from "../../components/CaseStudyGrid";
import Rating from "../../components/Rating";
import IsoCertifications from "../../LegalTranscription/IsoCertifications";
import TranslationIndustries from "../../FormalTranslation/TranslationIndustries";
import { data } from "../../../queries/servicePages";

function ServicePages(props) {
  const pageData = props.pageContext.data._source;
  const { data: bannerData } = pageData.widgets[0];

  bannerData.dataType = 1;
  const languageData = pageData.meta.other_language;
  const currentPageLang = pageData.meta.language;

  return (
    <Layout languageData={languageData} currentPageLang={currentPageLang}>
      <Seo
        title={pageData.meta.meta_title}
        description={pageData.meta.meta_description}
        keywords={pageData.meta.meta_keywords}
        slug={pageData.slug}
      />
      {bannerData && <Banner data={bannerData} background="bg-midSlate" />}
      {pageData.widgets &&
        pageData.widgets.map(({ widget, data }, i) => {
          data.dataType = 1;
          if (widget === "block_three_column_list_1") {
            return (
              <div key={i}>
                <TextRow data={data} />
              </div>
            );
          } else if (widget === "block_two_column_content_1") {
            return (
              <div key={i}>
                <DoubleColTwo data={data} />
              </div>
            );
          } else if (widget === "block_steps_1") {
            return (
              <div key={i}>
                <Newtranslation
                  data={data}
                  background="bg-prussian-blue"
                  darkMode={true}
                />
              </div>
            );
          } else if (widget === "block_supported_languages_1") {
            return (
              <div key={i}>
                <SupportedLanguages
                  data={data}
                  linkButton={false}
                  isLinkRequired={false}
                />
              </div>
            );
          } else if (widget === "block_three_column_list_2") {
            return (
              <div key={i}>
                <TranslationServiceGrid1 data={data} />
              </div>
            );
          } else if (widget === "block_steps_2") {
            return (
              <div key={i}>
                <MainUsp
                  data={data}
                  background="bg-prussian-blue"
                  darkMode={true}
                />
              </div>
            );
          } else if (widget === "block_iso_certifications") {
            return (
              <div key={i}>
                <IsoCertifications data={data} />
              </div>
            );
          } else if (widget === "block_supported_languages_2") {
            return (
              <div key={i}>
                <CaseStudyGrid data={data} />
              </div>
            );
          } else if (widget === "block_three_column_list_3") {
            return (
              <div key={i}>
                <TranslationServiceGrid1 data={data} />
              </div>
            );
          } else if (widget === "block_two_column_content_2") {
            return (
              <div key={i}>
                <TranslationIndustries data={data} />
              </div>
            );
          } else if (widget === "block_rating") {
            return (
              <div key={i}>
                <Rating data={data} />
              </div>
            );
          } else if (widget === "block_faq") {
            return (
              <div key={i}>
                <FAQSimple data={data} />
              </div>
            );
          } else if (widget === "block_banner_2") {
            return (
              <div key={i}>
                <CtaWithForm
                  data={data}
                  background="bg-[#5927B0]"
                  btnStyle="bg-black hover:bg-black"
                />
              </div>
            );
          } else if (widget === "block_three_column_list_4") {
            return (
              <div key={i}>
                <TranslationServiceGrid1 data={data} />
              </div>
            );
          } else if (widget === "block_certification_samples_1") {
            return (
              <div key={i}>
                <CertificationSamples data={data} />
              </div>
            );
          } else if (widget === "block_certification_samples_2") {
            return (
              <div key={i}>
                <CertificationSamples data={data} />
              </div>
            );
          } else if (widget === "block_two_column_content_3") {
            return (
              <div key={i}>
                <DoubleCol
                  data={data}
                  titleClassName="text-5xl leading-[75px] text-blue text-center font-primary font-bold pb-10"
                  className="flex xs:flex-col gap-6 xs:items-center sm:items-start mb-12 md:mb-0"
                />
              </div>
            );
          }
          return false;
        })}
      {/* <MultilingualSeoPricing data={pricingData} /> */}
      {/* <Newtranslation data={stepsData} /> */}
      {/* <MainUsp data={stepsData} background="bg-prussian-blue" darkMode={true} /> */}
      {/* <IsoCertifications data={isoCertificationsData} /> */}
    </Layout>
  );
}
export default ServicePages;
