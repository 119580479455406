import React from "react";
import BoxContainer from "../BoxContainer";
import Button from "../Button";
import Text from "../Text";
import "./CaseStudyGrid.style.css";
import { useForm } from "react-hook-form";
import { useLocation } from "@gatsbyjs/reach-router";
import { useMidForm } from "../../hooks/Forms/useMidForm";
import textResolver from "../../core/utils/text-resolver";

const CaseStudyGrid = ({
  data,
  background,
  form = false,
  textClasses,
  maxWidth = "max-w-4xl",
}) => {
  const { mutateAsync } = useMidForm();
  const location = useLocation();

  const { dataType = 0 } = data;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData = {
      email: data.email,
      pagelink: location.href,
    };
    mutateAsync(postData).then((_) => {
      reset();
    });
  };
  return (
    <BoxContainer
      background={`${background ? background : "bg-prussian-blue"}`}
      className="items-center text-center py-16"
    >
      <div className={`px-5 lg:px-0 pb-5 mx-auto text-center ${maxWidth}`}>
        <div className="max-w-4xl mx-auto">
          <Text
            highlightedColor={data?.highlightedColor}
            highlightedText={
              dataType === 1
                ? data.highlightWords
                : textResolver(data, "highlightWords")
            }
            as="h2"
          >
            {dataType === 1 ? data.title : textResolver(data, "title")}
          </Text>
        </div>
        <Text
          className={`text-lg font-opensans text-mediumGrey mb-12 text-center ${
            textClasses ? textClasses : " "
          }`}
          as="div"
        >
          {dataType === 1
            ? data.page_content
            : textResolver(data, "page_content")}
        </Text>
        {form ? (
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap pt-6 px-0 md:flex md:flex-nowrap md:space-x-4 md:items-center w-full md:w-10/12 lg:px-14 max-w-lg mx-auto justify-center">
              <input
                className="text-sm font-opensans shadow appearance-none border rounded w-full lg:w-10/12 py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder={
                  dataType === 1
                    ? data.inputPlaceholder
                    : textResolver(data, "inputPlaceholder")
                }
                style={{ borderColor: errors.email ? "#DD5451" : "" }}
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  },
                })}
              />
              <Button
                title={
                  dataType === 1
                    ? data.cta.text
                    : textResolver(data.cta, "text")
                }
                className="text-lg px-7 py-3 text-white text-center font-opensans font-medium bg-yellow rounded-sm cursor-pointer uppercase mt-8 md:mt-0 md:px-1 md:py-1"
              />
            </div>
          </form>
        ) : (
          <div className="flex text-white w-fill justify-center">
            <Button
              title={
                dataType === 1 ? data.cta?.text : textResolver(data.cta, "text")
              }
              href={data.cta?.href}
              className="text-lg px-7 py-3 text-center font-opensans font-medium bg-yellow rounded-full cursor-pointer uppercase hover:bg-yellowHover transition-colors"
            />
          </div>
        )}
      </div>
    </BoxContainer>
  );
};

export default CaseStudyGrid;
