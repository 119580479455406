import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "../../hooks/OptimizedImage";
import textResolver from "../../core/utils/text-resolver";
import Text from "../../components/Text";

function TranslationIndustries({ data }) {
  const { dataType = 0 } = data;
  return (
    <div className="bg-gradient-to-t from-[#E8F1F5] to-[#FFFFFF] pt-32 font-opensans xs:font-primary">
      <div className="lg:w-3/4 max-w-7xl mx-auto">
        <Text
          as="h2"
          highlightedText={
            dataType === 1
              ? data.highlightWords
              : textResolver(data, "highlightWords")
          }
          highlightedColor={data.highlightedColor}
          className="text-[32px] sm:text-[40px] w-10/12 flex justify-center items-center mx-auto font-bold text-[#00173A] text-center"
        >
          {dataType === 1 ? data.title : textResolver(data, "title")}
        </Text>
        <Text
          as="p"
          className="text-[18px] text-midBlue text-center w-full pt-6 leading-loose px-[1rem] sm:px-0"
        >
          {dataType === 1 ? data.subtitle : textResolver(data, "subtitle")}
        </Text>
        <div className="flex flex-wrap 0px:justify-center lg:justify-between pt-16 pb-20 px-4">
          {data.maindata &&
            data.maindata.map((item, i) => {
              return (
                <div
                  className="0px:w-full lg:w-[40%] relative 0px:mb-16 sm:mb-8"
                  key={i}
                >
                  <OptimizedImage
                    src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/legal-translation.webp"
                    // src={item.icon}
                    alt="translation"
                    className="flex 0px:justify-center md:justify-start mx-auto md:-mx-0 items-center"
                  />
                  <Text
                    as="h3"
                    className="text-[26px] text-midBlue font-semibold pt-5 pb-3"
                  >
                    {dataType === 1
                      ? item.heading
                      : textResolver(item, "heading")}
                  </Text>
                  <Text as="p" className="text-[18px] text-midBlue pb-16">
                    {dataType === 1
                      ? item.maintext
                      : textResolver(item, "maintext")}
                  </Text>
                  {item.cta && (
                    <Link
                      to={item.cta.href}
                      className="text-[#00173A] learnmore  text-[22px] font-bold underline absolute bottom-0 -left-0 hover:text-orange transition-all duration-300"
                    >
                      {dataType === 1
                        ? item.cta.text
                        : textResolver(item.cta, "text")}
                    </Link>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
export default TranslationIndustries;
