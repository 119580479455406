import React from "react";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";

function IsoCertifications({ data }) {
  const { dataType = 0 } = data;
  return (
    <div className="w-full pt-[60px] sm:pt-[135px] sm:flex-none flex-col justify-center text-center items-center">
      <Text
        as="h2"
        highlightedText={
          dataType === 1
            ? data.highlightWords
            : textResolver(data, "highlightWords")
        }
        highlightedColor={data.highlightedColor}
        className="text-[35px] text-midBlue font-secondary font-semibold sm:w-[579px] pt-4 text-center mx-auto"
      >
        {dataType === 1 ? data.title : textResolver(data, "title")}
      </Text>
      {data.section &&
        data.section.map((item, i) => {
          return (
            <div
              className="flex flex-col justify-between items-center mt-[-4rem]"
              key={i}
            >
              <div className="w-full sm:w-[579px] sm:pt-0 pt-[7rem]">
                <img src="" alt="" className="sm:hidden inline-block" />
                <Text
                  as="h3"
                  highlightedText={
                    dataType === 1
                      ? item.highlightWords
                      : textResolver(item, "highlightWords")
                  }
                  highlightedColor={item.highlightedColor}
                  className="text-[22px] font-secondary font-semibold text-midBlue pt-[4rem]"
                >
                  {dataType === 1 ? item.title : textResolver(item, "title")}
                </Text>
                <Text
                  as="p"
                  className="pt-[2rem] text-[18px] font-sans text-midBlue"
                >
                  {dataType === 1
                    ? item.description
                    : textResolver(item, "description")}
                </Text>
              </div>
              {item.images?.map((image, i) => {
                return (
                  <a target="_blank" href={image.fileUrl} key={i}>
                    <img
                      src={image.imgurl}
                      alt={
                        dataType === 1 ? image.alt : textResolver(image, "alt")
                      }
                      className="hidden sm:inline-block"
                    />
                  </a>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}
export default IsoCertifications;
