import { useMutation } from "react-query";
import { endpoints } from "../../core/utils/endpoints";
import http from "../../core/utils/http";

const addSubscribe = (postData) => {
  return http().post(endpoints.forms.midform, postData);
};

export function useMidForm() {
  return useMutation(addSubscribe, {
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      alert(errorMessage);
    },
  });
}
