const languageQuery = `{
  allServices {
    edges {
      node {
        data {
          slug
        }
      }
    }
  }
}`;

function servicesPagesData(client) {
  return new Promise(function (resolve, reject) {
    client
      .search({
        index: `${process.env.GATSBY_ES_PREFIX}dynamic_service_pages_formaltranslation`,
        type: "_doc",
        body: {
          query: {
            match_all: {},
          },
        },
        size: 1000,
      })
      .then(
        function (resp) {
          resolve(resp);
        },
        function (err) {
          console.trace(err.message);
        }
      );
  });
}

module.exports = { data: servicesPagesData, graphqlQuery: languageQuery };
