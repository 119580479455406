import React from "react";
import Text from "../Text";
import BoxContainer from "../BoxContainer";
import Button from "../Button";
import imageResolver from "../../core/image-resolver";
import textResolver from "../../core/utils/text-resolver";
import { Link } from "gatsby";
const Rating = ({ data }) => {
  const {
    dataType = 0,
    achievedstars,
    // totalstars,
    Ratinghead,
    ratingsummary,
    ratingcontent,
    cta,
    testimonialhead,
  } = data;

  const runCallback = (cb) => {
    return cb();
  };
  return (
    <>
      <BoxContainer
        className="py-8 px-5 2xl:px-0"
        background="bg-gradient-to-t from-gradient1  to-white"
      >
        <div className="pt-20 pb-8 md:px-8 xl:px-[138px] 2xl:px-16">
          <div className="md:flex md:gap-16 max-w-6xl mx-auto">
            <div className="md:w-1/2 mb-12 md:mb-0">
              <Text
                as="h2"
                className="text-yellow font-primary font-bold text-4xl xs:text-center sm:text-left"
              >
                {dataType === 1
                  ? achievedstars
                  : textResolver(data, "achievedstars")}
                &nbsp;
                {/* {dataType === 1 ? totalstars : textResolver(data, "totalstars")} */}
              </Text>
              <Text
                as="h2"
                className="text-blue font-primary font-bold text-4xl xs:text-center sm:text-left"
              >
                {dataType === 1 ? Ratinghead : textResolver(data, "Ratinghead")}
              </Text>
              <Text
                as="h2"
                className="text-blue text-xl leading-loose font-primary xs:text-center sm:text-left"
              >
                {dataType === 1
                  ? ratingsummary
                  : textResolver(data, "ratingsummary")}
              </Text>

              <div className="flex flex-row gap-3 xs:justify-center sm:justify-start">
                {runCallback(() => {
                  const row = [];
                  for (var i = 0; i < data.achievedstarsfigure; i++) {
                    row.push(
                      <svg
                        key={i}
                        width="60px"
                        height="60px"
                        className="w-[40px]"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 48 48"
                        enableBackground="new 0 0 48 48"
                      >
                        <polygon
                          fill="#FFCA28"
                          points="24,4 31,17 45,19 35,29 37,44 24,37 11,44 13,29 3,19 17,17 "
                        />
                        <g>
                          <polygon
                            fill="#F9A825"
                            points="24,25 31,17 24,4 &#9;"
                          />
                          <polygon
                            fill="#F9A825"
                            points="45,19 24,25 35,29 &#9;"
                          />
                          <polygon
                            fill="#F9A825"
                            points="37,44 24,25 24,37 &#9;"
                          />
                          <polygon
                            fill="#F9A825"
                            points="13,29 11,44 24,25 &#9;"
                          />
                          <polygon
                            fill="#F9A825"
                            points="3,19 24,25 17,17 &#9;"
                          />
                        </g>
                      </svg>
                    );
                  }
                  return row;
                })}
              </div>
              <Text
                as="div"
                className="text-blue font-opensans text-lg leading-8 xs:text-center sm:text-left"
                highlightedText={
                  dataType === 1
                    ? ratingcontent.highlightWords
                    : textResolver(ratingcontent, "highlightWords")
                }
                highlightedColor={ratingcontent.highlightedColor}
              >
                {dataType === 1
                  ? ratingcontent.description
                  : textResolver(ratingcontent, "description")}
              </Text>
              <Button
                title={dataType === 1 ? cta.text : textResolver(cta, "text")}
                href={data.cta.href}
                className="text-lg text-center bg-yellow rounded-full cursor-pointer xs:px-3 md:px-7 py-3 border-0 inline-flex items-center justify-center mt-7 mx-auto sm:mx-0 hover:bg-yellowHover transition-colors"
                // variant="capsule"
              />
            </div>
            <div className="md:w-1/2 mt-4 md:mt-0">
              <Text
                as="p"
                className="text-blue font-primary text-lg font-medium mb-5 xs:text-center sm:text-left"
              >
                {dataType === 1
                  ? testimonialhead
                  : textResolver(data, "testimonialhead")}
              </Text>
              {data.testimonialdata.map((item, i) => (
                <div
                  key={i}
                  className="bg-white rounded-[14px] p-6 mb-5 shadow-lighGray"
                >
                  <Link to={item.href}>
                    <Text
                      as="h3"
                      className="text-darkBlue font-opensans text-lg font-bold"
                    >
                      {dataType === 1
                        ? item.content
                        : textResolver(item, "content")}
                    </Text>
                    <div className="flex items-center mt-6">
                      <div>
                        <Text
                          as="p"
                          className="text-lightBluegray font-opensans text-lg font-semibold"
                        >
                          {dataType === 1
                            ? item.name
                            : textResolver(item, "name")}
                        </Text>
                      </div>
                      <div className="ml-auto">
                        {imageResolver(
                          dataType === 1
                            ? item.title
                            : textResolver(item, "title"),
                          item.icon,
                          item.className
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </BoxContainer>
    </>
  );
};

export default Rating;
