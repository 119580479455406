import React from "react";
import Upload from "../../assets/financial-video-assets/upload.svg";
import Transcribe from "../../assets/financial-video-assets/transcribe.svg";
import Translate from "../../assets/financial-video-assets/translate.svg";
import textResolver from "../../core/utils/text-resolver";
import Text from "../../components/Text";
import iconResolver from "../../core/icon-resolver";

function Newtranslation({ data }) {
  const { dataType = 0 } = data;
  return (
    <div className="flex items-center justify-center bg-darkBlue py-20">
      <div className="flex items-center justify-center max-w-screen-xl w-full 2xl:w-6/12 lg:w-7/12 flex-col px-4 xl:px-0">
        <Text
          as="h2"
          highlightedText={
            dataType === 1
              ? data.highlightWords
              : textResolver(data, "highlightWords")
          }
          highlightedColor={data.highlightedColor}
          className="text-3xl lg:text-5xl text-white font-secondary font-bold w-full lg:w-8/12 text-center mb-12 lg:mb-20"
        >
          {dataType === 1 ? data.title : textResolver(data, "title")}
        </Text>
        {/* <div className="grid 0px:grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 lg:gap-x-6 gap-y-12 pb-[5rem] max-w-5xl"> */}
        <div className="flex flex-wrap justify-center lg:gap-x-12 gap-y-12 pb-[5rem] max-w-5xl">
          {data.steps &&
            data.steps.map((step, i) => {
              return (
                <div className="text-center max-w-sm" key={i}>
                  <div className="mx-auto my-3 flex justify-center">
                    {iconResolver(step.icon)}
                  </div>
                  <h3 className="text-2xl font-bold xs:font-primary text-white leading-8">
                    {dataType === 1 ? step.title : textResolver(step, "title")}
                  </h3>
                  <p className="text-darkMist font-opensans leading-8">
                    {dataType === 1
                      ? step.description
                      : textResolver(data, "description")}
                  </p>
                </div>
              );
            })}
        </div>
        {data.videoUrl ? (
          <video width="320" height="240" controls>
            <source src={data.videoUrl} type="video/mp4" />
            <source src={data.videoUrl} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        ) : null}
      </div>
    </div>
  );
}

export default Newtranslation;
