import * as React from "react";
import BoxContainer from "../../BoxContainer";
import Text from "../../Text";
import { Link } from "gatsby";
import textResolver from "../../../core/utils/text-resolver";

const TranslationServiceGrid1 = ({ data }) => {
  const { dataType = 0 } = data;
  return (
    <BoxContainer
      background="bg-white"
      className="items-center py-16 px-5 2xl:px-0"
    >
      <div className="text-blue xs:text-center sm:text-left max-w-6xl xl:mx-auto">
        <Text
          as="h2"
          highlightedText={
            dataType === 1
              ? data.highlightWords
              : textResolver(data, "highlightWords")
          }
          highlightedColor={data.highlightedColor}
          className="text-3xl xs:font-primary font-semibold my-5"
        >
          {dataType === 1 ? data.title : textResolver(data, "title")}
        </Text>

        <Text
          className="text-sm font-opensans text-blue xs:text-center sm:text-left pb-6"
          as="div"
        >
          {dataType === 1 ? data.subtitle : textResolver(data, "subtitle")}
        </Text>
      </div>
      <ul className="list-none grid xs:grid-cols-1 md:grid-cols-3 gap-1 justify-center items-start w-full md:w-10/12 max-w-6xl mx-auto 2xl:w-full 2xl:gap-x-4">
        {data?.clients.length > 0
          ? data.clients.map((item, i) => (
              <div key={i}>
                {item.link ? (
                  <li className="flex gap-3 items-center">
                    <img
                      src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                      alt="check"
                      className="w-6 h-5"
                    />
                    <Link
                      to={
                        dataType === 1 ? item.link : textResolver(item, "link")
                      }
                      className="text-sm text-blue font-opensans cursor-pointer hover:text-yellowHover transition-colors"
                    >
                      {dataType === 1
                        ? item.title
                        : textResolver(item, "title")}
                    </Link>
                  </li>
                ) : (
                  <li className="text-sm text-blue font-opensans flex gap-3 py-2 items-center">
                    <img
                      src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                      alt="check"
                      className="w-6 h-5"
                    />
                    <span className="text-[22px] font-primary ">
                      {dataType === 1
                        ? item.title
                        : textResolver(item, "title")}
                    </span>
                  </li>
                )}
              </div>
            ))
          : ""}
      </ul>
      {/* <div className=" flex justify-center pt-32">
        <div className="xl:mx-[330px] text-center">
          <Text as="h2" className="text-blue text-[28px]">
            {dataType === 1 ? data.heading : textResolver(data, "heading")}
          </Text>
          <Text as="p" className="text-blue text-[18px] pt-8">
            {dataType === 1 ? data.para : textResolver(data, "para")}
          </Text>
          <div className="mt-10">
            <Link
              to="#"
              className="bg-[#FB7C32] px-10 text-white py-4 font-opensans, rounded-3xl text-sm mt-10"
            >
              {dataType === 1 ? data.linkText : textResolver(data, "linkText")}
            </Link>
          </div>
        </div>
      </div> */}
    </BoxContainer>
  );
};

export default TranslationServiceGrid1;
