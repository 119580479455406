import React from "react";
import BoxContainer from "../BoxContainer";
import Text from "../Text";
import AccordionSimple from "../AccordionSimple";
import textResolver from "../../core/utils/text-resolver";

function FAQSimple({
  data,
  background = "bg-gradient-to-t from-gradient1  to-white",
  maxWidth = "max-w-6xl",
}) {
  const { dataType = 0, title, description, accordionItems } = data;
  return (
    <BoxContainer
      className="px-5 2xl:px-0 py-14 md:py-24"
      background={background}
    >
      <div
        className={`w-full ${maxWidth} mx-auto grid xs:grid-cols-1 lg:grid-cols-5 gap-10 items-start`}
        id="link-orange"
      >
        <div className="max-w-4xl mx-auto col-span-2">
          <Text
            as="h2"
            className="text-4xl text-blue font-primary font-bold mb-8"
          >
            {dataType === 1 ? title : textResolver(data, "title")}
          </Text>
          {description && (
            <Text
              as="p"
              className="text-lg leading-8 text-midBlue text-center font-opensans mb-10 px-4 lg:px-0"
            >
              {dataType === 1 ? description : textResolver(data, "description")}
            </Text>
          )}
        </div>
        <div className="col-span-3">
          {accordionItems.map((item, i) => {
            return (
              <AccordionSimple
                key={i}
                heading={
                  dataType === 1 ? item.heading : textResolver(item, "heading")
                }
                description={
                  dataType === 1
                    ? item.description
                    : textResolver(item, "description")
                }
              />
            );
          })}
        </div>
      </div>
    </BoxContainer>
  );
}
export default FAQSimple;
